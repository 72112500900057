import React from "react";
import{ Buffer} from "buffer";

const ItemImage = ({ isEditing, editedItem, item, setEditedItem }) => {
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setEditedItem((prevItem) => ({
        ...prevItem,
        item_image: reader.result, // store base64 string
      }));
    };
    if (file) reader.readAsDataURL(file);
  };

  const getItemImageSrc = () => {
    // Check if item_image is a raw buffer or a base64 encoded string
    if (item.item_image && item.item_image.data) {
      return `data:${item.mimeType};base64,${Buffer.from(item.item_image.data).toString("base64")}`;
    }
    return item.item_image;
  };

  return isEditing ? (
    <>
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {editedItem.item_image && (
        <img src={editedItem.item_image} alt={editedItem.item_name} width="50" height="50" />
      )}
    </>
  ) : item.item_image ? (
    <img src={getItemImageSrc()} alt={item.item_name} width="50" height="50" />
  ) : (
    "No Image"
  );
};

export default ItemImage;
