import React from "react";
import { TextField, Checkbox } from "@mui/material";

const StatusField = ({ isEditing, statusField, item, editedItem, setEditedItem }) => {
  const status = JSON.parse(item[statusField] || "[]")[0] || { status: false, "start time": "N/A", "end time": "N/A" };

  const handleStatusChange = (field, value) => {
    const currentStatus = JSON.parse(editedItem[statusField] || "{}");
    setEditedItem((prevItem) => ({
      ...prevItem,
      [statusField]: JSON.stringify([{ ...currentStatus, [field]: value }]),
    }));
  };

  return isEditing ? (
    <>
      <Checkbox
        checked={status.status}
        onChange={(e) => handleStatusChange("status", e.target.checked)}
      />
      <TextField
        value={status["start time"]}
        onChange={(e) => handleStatusChange("start time", e.target.value)}
        label="Start Time"
        size="small"
      />
      <TextField
        value={status["end time"]}
        onChange={(e) => handleStatusChange("end time", e.target.value)}
        label="End Time"
        size="small"
      />
    </>
  ) : (
    `${status.status ? "True" : "False"} / ${status["start time"]} : ${status["end time"]}`
  );
};

export default StatusField;
