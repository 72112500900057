import React, { useState } from "react";
import { TextField, IconButton, Chip, Box, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const PriceInput = ({ prices, setPrices }) => {
  const [newPrice, setNewPrice] = useState("");
  const [error, setError] = useState("");

  const handleAddPrice = () => {
    const priceValue = parseFloat(newPrice.trim());
    if (newPrice.trim() && !isNaN(priceValue)) {
      if (!prices.includes(priceValue)) {
        setPrices([...prices, priceValue]);
        setNewPrice("");
        setError("");
      } else {
        setError("Price already exists.");
      }
    } else {
      setError("Please enter a valid price.");
    }
  };

  const handleDeletePrice = (priceToDelete) => {
    setPrices(prices.filter((price) => price !== priceToDelete));
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        label="Add Price"
        type="number"
        value={newPrice}
        onChange={(e) => setNewPrice(e.target.value)}
        error={!!error}
        helperText={error || ""}
        fullWidth
      />
      <IconButton
        onClick={handleAddPrice}
        color="primary"
        sx={{ mt: 1 }}
        disabled={!newPrice.trim() || !!error}
      >
        <AddCircleIcon />
      </IconButton>
      <Box sx={{ mt: 2 }}>
        {prices.length > 0 ? (
          prices.map((price, index) => (
            <Chip
              key={index}
              label={`${price.toFixed(2)}`}
              onDelete={() => handleDeletePrice(price)}
              sx={{ marginRight: 1, marginBottom: 1 }}
            />
          ))
        ) : (
          <Typography variant="body2" sx={{ mt: 1 }}>
            No prices added yet.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PriceInput;
