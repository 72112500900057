import { Box } from '@mui/material';
import './App.css';
import PermanentDrawerLeft from './components/drawer/drawer'; 

function App() {
  return (
    <>
    <Box className="App">
      <PermanentDrawerLeft/>
    </Box>
    </>
  );
}

export default App;