import React from "react";
import {Box, Typography } from "@mui/material";

const OrderManagement = ({ description, setDescription }) => {
 


  return (
    
    <Box sx={{ mt: 2, mb: 2 }}>
     <Typography>Hello</Typography>
    </Box>
  );
};

export default OrderManagement;
