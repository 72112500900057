import React, { useState} from "react";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import CategorySelect from "./categorySelect";
import ItemNameSelect from "./itemName";
import SubcategorySelect from "./subCategorySelect";
import ImageUpload from "./imageUpload";
import PriceInput from "./itemPrice";
import IngredientInput from "./itemIngredients";
import Description from "./itemDescription";
import ItemType from "./itemType";
import { toast } from "react-toastify";

const url = "https://www.bbgrestaurant.com/bbgapi/";

const ItemAdd = ({ availableItems }) => {
  const [itemName, setItemName] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [itemType, setType] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [prices, setPrices] = useState([]);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [, setItemId] = useState("");

  const fetchItemId = async (itemName, category) => {
    try {
      console.log("Fetching item ID with:", { item_name: itemName, category });
  
      const response = await axios.get(`${url}/item`, {
        params: {
          item_name: itemName,
          category: category,
        },
      });
  
      console.log("Response data:", response.data);
  
      const items = response.data.allItemsGot;
  
      // Find the item with the matching name and category
      const item = items.find(
        (item) =>
          item.item_name === itemName && item.item_category.replace(/"/g, '') === category
      );
  
      if (item && item.item_id) {
        return item.item_id;
      } else {
        throw new Error("Item ID not found");
      }
    } catch (error) {
      console.error("Error fetching item ID:", error);
      toast.error("Failed to retrieve item ID.");
      return null;
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit button clicked...");
  
    if (!image || image.size > 1 * 1024 * 1024) {
      toast.error("Please upload an image under 1MB.");
      return;
    }
    
    // Default category value to 0 if not selected
    const selectedCategory = category || "0";
  
    const fetchedItemId = await fetchItemId(itemName, selectedCategory);
    if (!fetchedItemId) {
      return; // Exit if the item ID could not be fetched
    }
  
    console.log("subcategory:", subcategory);
    console.log("item_image:", image);
    console.log("item_ingredients:", ingredients);
    console.log("Price:", prices);
  
    const data = new FormData();
  
    data.append("item_subcategory", subcategory);
    data.append("item_type", itemType || "");
    data.append("item_image", image);
    data.append("item_description", description);
    data.append("item_ingredients", JSON.stringify(ingredients));
    data.append("item_price", JSON.stringify(prices));
    data.append("id", fetchedItemId); // Use the fetched item ID
  
    try {
      const response = await axios.post(
        `${url}/itemSubcategory`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.data.message === "Item added successfully.") {
        toast.success("Item added successfully.");
  
        setSubcategory("");
        setType("");
        setImage(null);
        setIngredients([]);
        setPrices([]);
        setItemId("");
        e.target.reset();
      } else {
        toast.error("Failed to add item.");
      }
    } catch (err) {
      console.error("Error uploading item:", err);
      toast.error("Item upload failed.");
    }
  };
  
  

  return (
    <Box
      component="form"
      method="post"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 400,
        margin: 10,
        padding: 5,
        border: "2px solid black",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
        Add Item
      </Typography>

      <ItemNameSelect
        itemName={itemName}
        setItemName={setItemName}
        availableItems={availableItems}
      />
      <CategorySelect category={category} setCategory={setCategory} />
      <SubcategorySelect
        category={category}
        subcategory={subcategory}
        setSubcategory={setSubcategory}
      />
      <ItemType setType={setType} />
      <ImageUpload setImage={setImage} />
      <Description setDescription={setDescription} />
      <IngredientInput
        ingredients={ingredients}
        setIngredients={setIngredients}
      />
      <PriceInput prices={prices} setPrices={setPrices} />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>
    </Box>
  );
};

export default ItemAdd;
