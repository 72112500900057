import React from "react";
import { TableRow, TableCell, TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ItemImage from "./itemImage";
import StatusField from "./itemStatus";

const EditableTableRow = ({
  index,
  item,
  isEditing,
  editedItem,
  setEditIndex,
  setEditedItem,
  items,
  setItems,
  deleteItem, // Receive deleteItem as a prop
}) => {
  const handleFieldChange = (field, value) => {
    setEditedItem((prevItem) => ({ ...prevItem, [field]: value }));
  };

  const handleEditClick = () => {
    setEditIndex(index);
    setEditedItem(item);
  };

  const handleSaveClick = () => {
    const updatedItems = [...items];
    updatedItems[index] = editedItem;
    setItems(updatedItems);
    setEditIndex(-1);
    setEditedItem(null);
  };

  const handleCancelClick = () => {
    setEditIndex(-1);
    setEditedItem(null);
  };

  const handleDeleteClick = () => {
    // Call the deleteItem function with the item ID
    deleteItem(item._id);
  };

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_name || ""}
            onChange={(e) => handleFieldChange("item_name", e.target.value)}
          />
        ) : (
          item.item_name || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_category || ""}
            onChange={(e) => handleFieldChange("item_category", e.target.value)}
          />
        ) : (
          item.item_category || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_subcategory || ""}
            onChange={(e) =>
              handleFieldChange("item_subcategory", e.target.value)
            }
          />
        ) : (
          item.item_subcategory || "N/A"
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={editedItem.item_type || ""}
            onChange={(e) => handleFieldChange("item_type", e.target.value)}
          />
        ) : (
          item.item_type || "N/A"
        )}
      </TableCell>
      <TableCell>
        <ItemImage
          isEditing={isEditing}
          editedItem={editedItem}
          item={item}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>{item.item_description || "N/A"}</TableCell>
      <TableCell>{item.item_ingredients || "N/A"}</TableCell>
      <TableCell>{item.item_price || "N/A"}</TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_popular"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_special"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_offer_festival"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>
      <TableCell>
        <StatusField
          isEditing={isEditing}
          statusField="item_instant_offer"
          item={item}
          editedItem={editedItem}
          setEditedItem={setEditedItem}
        />
      </TableCell>

      <TableCell>
        {isEditing ? (
          <>
            <IconButton onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EditableTableRow;
