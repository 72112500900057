import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import EditableTableRow from "./editableTableRow";
import PaginationControl from "./paginationControl";

const PaginatedItemTable = ({ submittedItems = [], onUpdate = () => {} }) => {
  const [items, setItems] = useState(
    Array.isArray(submittedItems[0]) ? submittedItems[0] : []
  );
  const [editIndex, setEditIndex] = useState(-1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editedItem, setEditedItem] = useState(null);

  // Updated delete function to dynamically use itemId
  const deleteItemFromDatabase = async (itemId) => {
    try {
      const response = await fetch(
        `https://www.bbgrestaurant.com/bbgapi/joinData/${itemId}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete item");
      }
      // Update local state if deletion was successful
      setItems(items.filter((item) => item._id !== itemId));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Item Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Item Type</TableCell>
              <TableCell>Item Image</TableCell>
              <TableCell>Item Description</TableCell>
              <TableCell>Item Ingredients</TableCell>
              <TableCell>Item Price</TableCell>
              <TableCell>Popular</TableCell>
              <TableCell>Special</TableCell>
              <TableCell>Offer Festival</TableCell>
              <TableCell>Instant Offer</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.length === 0 ? (
              <TableRow>
                <TableCell colSpan={13} align="center">
                  No items found.
                </TableCell>
              </TableRow>
            ) : (
              items
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <EditableTableRow
                    key={item._id} // Use a unique key (e.g., item._id)
                    index={index}
                    item={item}
                    isEditing={editIndex === index}
                    editedItem={editedItem}
                    setEditIndex={setEditIndex}
                    setEditedItem={setEditedItem}
                    items={items}
                    setItems={setItems}
                    deleteItem={deleteItemFromDatabase} // Pass the delete handler function
                  />
                ))
            )}
          </TableBody>
        </Table>
        <PaginationControl
          items={items}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PaginatedItemTable;
